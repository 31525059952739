<template>
  <div>
    Coming soon..
  </div>
</template>

<script>
export default {
  name: 'VuePlayer',
  props: {
    voiceUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      options: {
        controls: ['play-large', 'play', 'progress', 'current-time']
      }
    }
  },
  computed: {
    player () { return this.$refs.plyr.player }
  }
}
</script>
